$(document).ready( function() {
  $('#grid-container').cubeportfolio({
    layoutMode: 'grid',
    sortToPreventGaps: true,
    mediaQueries: [{
      width: 1500,
      cols: 3
    }, {
      width: 1100,
      cols: 3
    }, {
      width: 800,
      cols: 3
    }, {
      width: 480,
      cols: 2
    }, {
      width: 320,
      cols: 1
    }],
    defaultFilter: '*',
    animationType: 'quicksand',
    gapHorizontal: 34,
    gapVertical: 34,
    gridAdjustment: 'default',
    caption: 'minimal',
    displayType: 'sequentially',
    displayTypeSpeed: 100,

  });



  // init cubeportfolio
  $('#js-grid-slider-testimonials').cubeportfolio({
    layoutMode: 'slider',
    drag: true,
    auto: false,
    autoTimeout: 5000,
    autoPauseOnHover: true,
    showNavigation: true,
    showPagination: true,
    rewindNav: true,
    scrollByPage: false,
    gridAdjustment: 'responsive',
    mediaQueries: [{
      width: 1,
      cols: 1
    }],
    gapHorizontal: 0,
    gapVertical: 0,
    caption: '',
    displayType: 'default',
  });




  var singlePage = $('#js-singlePage-container').children('div');
  $('#js-grid-slider-projects').cubeportfolio({
    layoutMode: 'slider',
    drag: true,
    auto: false,
    autoTimeout: 5000,
    autoPauseOnHover: true,
    showNavigation: true,
    showPagination: false,
    rewindNav: false,
    scrollByPage: false,
    gridAdjustment: 'responsive',
    mediaQueries: [{
      width: 1,
      cols: 1
    }],
    gapHorizontal: 0,
    gapVertical: 25,
    caption: '',
    displayType: 'fadeIn',
    displayTypeSpeed: 100,
  });



});

